var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "hero is-fullheight",
      staticStyle: { "background-color": "#f1f1f1" },
    },
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c("div", { staticClass: "hero-body" }, [
        _c(
          "div",
          { staticClass: "container", staticStyle: { width: "99vw" } },
          [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }),
              _c("div", { staticClass: "column" }, [
                _c("div", { attrs: { id: "login" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isIE,
                          expression: "isIE",
                        },
                      ],
                      staticStyle: { padding: "2rem" },
                    },
                    [_vm._v(" ")]
                  ),
                  _c("div", [
                    _c("figure", { staticClass: "has-text-centered" }, [
                      _c("img", {
                        staticStyle: { "max-width": "250px" },
                        attrs: { src: _vm.activeSite.logoUrl },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isInvalidPass,
                            expression: "isInvalidPass",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-times has-text-danger",
                        }),
                        _vm._v(" " + _vm._s(_vm.failedLoginMessage) + " "),
                        _c("br"),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "form",
                      {
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label is-size-7" }, [
                            _vm._v("Email Address"),
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.email,
                                  expression: "input.email",
                                },
                              ],
                              class:
                                " input " +
                                (_vm.isInvalidPass ? " is-danger " : ""),
                              attrs: { type: "email", name: "email" },
                              domProps: { value: _vm.input.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label is-size-7" }, [
                            _vm._v("Password"),
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.password,
                                  expression: "input.password",
                                },
                              ],
                              class:
                                "input " +
                                (_vm.isInvalidPass ? " is-danger" : ""),
                              attrs: { type: "password", name: "password" },
                              domProps: { value: _vm.input.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "password",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "is-clearfix" }, [
                          _c("div", { staticClass: "field is-pulled-left" }, [
                            _c("div", { staticClass: "control" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.input.rememberMe,
                                    expression: "input.rememberMe",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "rememberMe",
                                  name: "rememberMe",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.input.rememberMe)
                                    ? _vm._i(_vm.input.rememberMe, null) > -1
                                    : _vm.input.rememberMe,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.input.rememberMe,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.input,
                                            "rememberMe",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.input,
                                            "rememberMe",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.input, "rememberMe", $$c)
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "is-size-7",
                                  staticStyle: { padding: "0px 7px" },
                                  attrs: { for: "rememberMe" },
                                },
                                [_vm._v("Remember Me for 30 Days")]
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "field is-pulled-right" }, [
                            _c("div", { staticClass: "control" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-accent",
                                  class: { "is-loading": _vm.isLoggingIn },
                                  on: {
                                    click: function ($event) {
                                      return _vm.login()
                                    },
                                  },
                                },
                                [_vm._v("Login")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "has-text-centered",
                        staticStyle: { "padding-top": "30px" },
                      },
                      [
                        _c("p", [
                          _c(
                            "a",
                            {
                              attrs: { id: "lostpwd", href: "#" },
                              on: { click: _vm.sendPasswordReset },
                            },
                            [_vm._v("Lost your password?")]
                          ),
                        ]),
                        _c(
                          "p",
                          { staticStyle: { "padding-bottom": "100px" } },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  id: "backtosite",
                                  href: _vm.activeSite.linkedUrl,
                                },
                              },
                              [
                                _vm._v(
                                  "← Back to " +
                                    _vm._s(_vm.activeSite.displayName)
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "column" }),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isIE,
              expression: "isIE",
            },
          ],
          staticClass: "is-size-5",
          staticStyle: {
            "text-align": "center",
            backgroundcolor: "#e69138",
            color: "white",
            padding: "20px",
            position: "absolute",
            top: "20px",
            width: "100%",
          },
        },
        [
          _c("B", [_vm._v("Internet Explorer users:")]),
          _vm._v(
            "  For a better client portal experience, please use either Google Chrome or Firefox as your browser. "
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isUnsupported,
              expression: "isUnsupported",
            },
          ],
          staticClass: "is-size-5",
          staticStyle: {
            "text-align": "center",
            backgroundcolor: "#e69138",
            color: "white",
            padding: "20px",
            position: "absolute",
            top: "20px",
            width: "100%",
          },
        },
        [
          _vm._v(
            " To access the client portal, please use either Google Chrome or Firefox as your browser. "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }